@charset "UTF-8";
@use "../global" as *;

.l-main#notfound {
    .notfound {
        .m-fv {
            background-image: url(../../img/company/fv.jpg);
        }
        .notfound__container {
            padding-top: 200px;
            padding-bottom: 300px;
            @include bpTb {
                padding-top: 12rem;
                padding-bottom: 15rem;
            }
        }
        .notfound__inner {
            padding-top: 100px;
            @include bpTb {
                padding-top: 5rem;
            }
        }
        .notfound__title {
        }
        .notfound__detail {
            font-size: 1.6rem;
            @include font(16, 400, 32, 0);
            text-align: center;
        }
        .m-btn1 {
            margin-inline: auto;
            margin-top: 8rem;
        }
    }
}