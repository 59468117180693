@charset "UTF-8";
@font-face {
  font-family: "DIN1451";
  src: url("../../fonts/din-1451-engschrift.otf") format("opentype");
}
@font-face {
  font-family: "arial-hebrew";
  src: url("../../fonts/arial-hebrew.ttf") format("truetype");
}
.pc-hidden {
  display: none;
}
@media screen and (max-width: 768px) {
  .pc-hidden {
    display: inline-block;
  }
}

.sp-hidden {
  display: inline-block;
}
@media screen and (max-width: 768px) {
  .sp-hidden {
    display: none;
  }
}

/*-- globalフォルダの中の_index.scss --*/
.l-main#notfound .notfound .m-fv {
  background-image: url(../../img/company/fv.jpg);
}
.l-main#notfound .notfound .notfound__container {
  padding-top: 200px;
  padding-bottom: 300px;
}
@media screen and (max-width: 768px) {
  .l-main#notfound .notfound .notfound__container {
    padding-top: 12rem;
    padding-bottom: 15rem;
  }
}
.l-main#notfound .notfound .notfound__inner {
  padding-top: 100px;
}
@media screen and (max-width: 768px) {
  .l-main#notfound .notfound .notfound__inner {
    padding-top: 5rem;
  }
}
.l-main#notfound .notfound .notfound__detail {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0em;
  text-align: center;
}
.l-main#notfound .notfound .m-btn1 {
  margin-inline: auto;
  margin-top: 8rem;
}